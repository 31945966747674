.header-buttons-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .button-subgroup {
    display: flex;
    flex-direction: row;
  }
}


.es-layer-tree {
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
  overflow-y: hidden;

  .layer-list {
    overflow-y: auto;
  }

  &.loading {
    justify-content: center;
    align-items: center;
  }

  .no-layers-placeholder {
    align-self: center;
    justify-self: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.es-layertree-drag-preview {
  background: $es-light-grey-blue;
  padding: 6px;
  padding-left: 8px;
  padding-right: 10px;
  border-radius: 4px;
  width: 100px;
}

.es-layertree-drag-source {
  color: $es-light-grey-blue !important;
}

.es-layertree-drop-target {
  color: $es-orange-hint;
}

.es-layertree-placeholder-container {
  position: relative;
}

.es-layertree-placeholder {
  background-color: $es-orange-hint;
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}

.es-layertree-outliner {
  display: block;
  padding-inline-start: 5px;
  margin-top: 0;
  margin-bottom: 0;
  ul {
    padding-inline-start: 0px;
  }
  li {
    display: block;

    .tree-node-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.1em;

      .tree-node-grouped {
        width: 100%;
        display: flex;
        flex-direction: column;

        .layer-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.16em 0.32em;
          border-radius: 4px;

          &.selected {
            background: $es-light-grey-blue;
          }

          .selectable-onclick {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
          }

          .drag-handle {
            margin-left: -5px;
            margin-right: 10px;
            color: $es-white;
            cursor: move;
            vertical-align: middle;
          }

          .label {
            padding-top: 2px;
            padding-left: 10px;
            padding-right: 10px;
            flex-grow: 1;

            input {
              padding: 2px;
              padding-bottom: 0px;
              padding-top: 0px;
              border: 0px;
              margin-left: -2px;
              margin-top: -6px;
              margin-bottom: -7px;
            }
          }
          button {
            margin-top: 0;
            margin-bottom: 0;
          }
          .visible {
            span {
              margin-right: 5px;
            }
          }
        }

        .description-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          border-top: 1px solid $es-medium-light-blue;
          font-size: $font-size-base;

          &.description-indent {
            padding-left: 24px;
          }

          .can-edit {
            cursor: pointer;
          }

          .description-minimal-textarea {
            margin: -8px;
            padding: 8px;
            font-size: $font-size-base;
          }
        }
      }
    }
  }
}

.project-details-edit-field {
  display: inline-block;
  min-width: 2em;
  width: 100%;

  &.editable {
    cursor: pointer;
  }
}
