.es-dopesheet {
  width: 100%;
  background: $es-light-grey-blue;
  border: 1px solid $es-medium-light-blue;
  margin-top: -5em;
  position: relative;

  .entry {
    border-bottom: 1px solid $es-charcoal-blue;
    height: 1.5em;
  }

  .label {
    color: $es-charcoal-blue;
    position: absolute;
    z-index: 2;
  }

  .keyframecount {
    color: $es-charcoal-blue;
    position: absolute;
    z-index: 2;
    right: 0;
    padding-right: 0.2em;
  }

  canvas {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}
