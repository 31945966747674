

.es-dialog {
  position: absolute;
  background-color: $es-white;
  color: $es-black;
  border-radius: 6px 6px 4px 4px;
  -webkit-box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1),
    0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2),
    0 8px 24px rgba(17, 20, 24, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 0;
  pointer-events: all;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  width: 500px;
  outline: none;

  .es-dialog-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    background-color: $es-charcoal-blue;
    border-bottom: 1px solid $es-grey-border;
    color: $es-white;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0;
    padding: 0;
    padding-left: 1em;
    
    h1 {
      user-select: none;
      margin: 0;
      padding: 0;
      padding-top: 0.35em;
      padding-bottom: 0.25em;
      vertical-align: middle;
      font-family: $es-font-title;
      font-size: $font-size-lg;
      font-weight: 600;
      flex-grow: 1;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      line-height: inherit;
    }

    .close-button {
      align-self: flex-end;
      height: 100%;
      top: 0px;
      right: 0px;
      background-color: $es-charcoal-blue;
      border: none;
      border-radius: 0px 4px 0px 0px;
      &:hover {
        background-color: $es-darkest-blue;
      }
    }
  }

  .es-dialog-body {
    padding-top: 1.0em;
    padding-bottom: 0.3em;
    padding-left: 1em;
    padding-right: 1em;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;

    &.no-padding {
      padding: 0;
    }

    &.vertical {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  .es-dialog-footer {
    width: 100%;
    background-color: $es-white;
    border-top: 1px solid $es-grey-border;
    padding: 1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    flex: 0 0 auto;

    button {
      margin-left: 0.5em;
    }
  }


}


.es-draggable-dialog {
  position: absolute;

  &.dragging {
    .es-dialog-header {
      cursor: move;
      background: $es-darkest-blue;
    }
  }
}

