// While we can't really support resolutions under 1024px, we can at least make the site more readable at that size.

@media (max-width: 1024px) {
    * {
        font-size: 0.75rem;
    }

    .es-bottom-bar {
        height: 100px;
    }

    .mapview {
        height: calc(100% - 100px);
    }

    .es-search-box {
        height: 2.7em;
        padding: 0.2em;
        padding-left: 1em;
        border-radius: 2em;
        width: 20em;
    }
}
