.user-settings-nav-panel {
  margin: 2em 0 0 2em;
  padding-right: 3em;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $es-white-dim;
  white-space: nowrap;

  &.truncated {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  section {
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
    border-bottom: 1px solid $es-white-dim;
    margin-bottom: 1.5em;
  }

  h1 {
    font-weight: 300;
    border-bottom: 0;
    font-size: $font-size-lg;
    margin: 0 0 1em;
    width: 9em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    font-size: $font-size-base;
    color: $es-black;
    margin-bottom: 1em;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-color 150ms ease-out;

    &.active {
      font-weight: bold;
      border-bottom: 1px solid $es-orange-hint;
    }

    &:hover {
      border-bottom: 1px solid $es-orange-hint;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.settings-main-section {
  padding: 3em;
  width: 100%;
  overflow-y: auto;

  h2 {
    font-size: $font-size-base;
    font-weight: bold;
    margin-top: 0;

    .title-tooltip {
      display: inline-block;
      margin-left: 0.5em;
    }
  }

  .settings-block {
    width: 100%;
    background-color: $es-white;
    border: 1px solid $es-white-dim;
    padding: 1em;
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;

    .settings-block-row {
      height: 2.25em;
      margin-bottom: 1em;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:last-of-type {
        margin-bottom: 0;
      }

      > span {
        width: 8rem;
        padding-right: 0.5em;
      }

      .error-info-box {
        margin-left: 1.5rem;
        color: red;
        display: flex;
        white-space: normal;
      }
    }

    .settings-block-multiline-row {
      margin-bottom: 2em;
      display: flex;
      flex-direction: column;

      h3 {
        margin-top: 0;
        margin-bottom: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      > label {
        font-weight: bold;
      }
    }
  }
}

.settings-input-field-group {
  .bp4-input-action {
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8em;

    &:last-child {
      right: 0.8em;
    }
  }
}

.settings-input-field, .bp4-input {
  min-width: 17.5em;
  background-color: $es-grey-background;
  border-radius: 0.5em;
  box-shadow: none;
  border: 1px solid transparent;
  font-size: 1em;

  &.dirty {
    border-color: $es-orange-hint;
  }

  &.error {
    border-color: red;
  }

  &:focus {
    box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0),
      inset 0 0 0 1px rgba(17, 20, 24, 0.1),
      inset 0 1px 1px rgba(17, 20, 24, 0.2);
  }

  &.white {
    background-color: $es-white;
  }
}

.complex-editable-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  line-height: 2.25em;

  .complex-editable-value {
    min-width: 18em;
    padding: 0 0.75em;
    display: inline-flex;
    align-items: center;

    .status-icon-container {
      margin-left: 1em;
    }
  }

  .action-buttons {
    margin: 0 1em;

    > *:not(:last-child) {
      margin-right: 0.5em;
    }
  }
}

.settings-dialog {
  background: $es-grey-background;
  color: $es-black;
  font-size: $font-size-lg;
  padding: 1.5em 0 1em;

  .dialog-body {
    padding: 1em 0 0;
    margin: 0 1em;
  }

  form {
    margin-top: 1em;
    font-size: $font-size-lg;

    p {
      margin: 0 1em;
    }

    button[type="submit"] {
      display: none;
    }

    label {
      margin-left: 0.5em;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 1em;

    &.space-between {
      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    button:not(:first-of-type) {
      margin-left: 1em;
    }
  }
}

.settings-header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;

  > h2 {
    margin-bottom: 0;
  }
}

.settings-table {
  width: 100%;
  table-layout: auto;
  background: $es-white;
  border: 1px solid $es-white-dim;
  margin-bottom: 1em;
  padding: 0.5em;

  th {
    text-align: left;
  }

  th,
  td {
    padding: 0 0.5em;
  }

  tr {
    line-height: 2.5em;
  }
  tbody > tr {
    transition: background-color ease-in 100ms;
    &:hover {
      background-color: darken($es-white, 5%);
    }
  }

  .key-table-cell {
    cursor: pointer;
  }
}

.create-apikey-modal-body {
  > h2 {
    margin-top: 0;
    font-size: $font-size-lg;
    margin-bottom: 1em;
  }
  > div {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    > span {
      margin-right: 2em;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .grow {
    flex-grow: 1;
  }
  .dialog-error-message {
    color: $red;
    border: 1px solid $red;
    border-radius: 5px;
    padding: 0.5em 0.75em;
  }
}

.settings-usage-header {
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 1em;
}

.settings-chart-placeholder-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
}

.inline-checkbox {
  display: inline-block;
}


.settings-tier-change-box {
  width:  70%;
  max-width: 78ch;
  margin: 0 auto;
  padding: 1em;
  margin-top: 2em;
}