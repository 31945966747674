// TODO: Most of the components here should be moved out into their own
//       components, and made to have cleaner namespacing and such.
//       This should really only contain base classes which are component
//       agnostic and/or only override normalization.

:active,
:focus {
  // TODO: This is terrible for accessability.
  outline: none;
}

::-webkit-scrollbar,
.information-layout-body > ::-webkit-scrollbar,
.es-panel > ::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track,
.information-layout-body > ::-webkit-scrollbar-track,
.es-panel > ::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb,
.information-layout-body > ::-webkit-scrollbar-thumb {
  background-color: $es-charcoal-blue;
  border-radius: 0.5em;
  border: 0.2em solid $es-grey-background;
  min-height: 4em;
}

.es-panel > ::-webkit-scrollbar-thumb {
  background-color: $es-white-dim;
  border-radius: 0.5em;
  border: 0.2em solid $es-charcoal-blue;
  min-height: 4em;
}

.muted {
  color: $es-white-dim;
}

.es-datetime-box {
  text-align: center;
  font-size: $font-size-lg;
  font-family: $es-font-title;
  margin-top: 0.5em;
}

.es-statistic {  
  .unit {
    font-size: 0.6em;
  }
  .maximum {
    text-align: right;
  }

  h1 {
    text-align: center;
    font-size: 3em;
    margin: 0;
  }
}

.es-statistics-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 1em;
  margin-bottom: 1em;
  .es-statistic {
    box-shadow: 2px 2px 5px #444;

    margin: 0.5em;
    margin-bottom: 1em;
    width: 17%;
    min-width: 200px;
  }

  &.vertical {
    flex-direction: column;
    align-items: center;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-right: 1em;
    margin-left: 1em;
    .es-statistic {
      width: 100%;
      margin-bottom: 1em;
    }
  }
}

.float-right {
  display: inline-block;
  float: right;
}

.logo-text {
  color: $es-darkest-blue;
  font-variant: all-small-caps;
  font-family: "Lexend";
  letter-spacing: 0.24em;
  font-size: 2.3em;
  font-weight: 500;
}

.project-logo-box {
  position: fixed;
  bottom: 160px;
  left: 70px;
  z-index: 1000;
  pointer-events: none;
  img {
    width: 200px;
    height: 50px;
  }
}

.es-logo-centered {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.es-error-boundary {
  margin: 3em;
  font-size: $font-size-lg;
}

.es-mapbox-cardbody {
  overflow: hidden;
  padding: 0px;
  border-radius: 0rem;
}

.es-mapbox {
  z-index: 0;
  height: 100%;
  width: 100%;
}

.leaflet-tooltip.permanent-label {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
  top: 8px;
}

.es-mapbox-fullscreen {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
}

.leaflet-control-attribution a,
.leaflet-popup-content a {
  color: #fff;
}

.main {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.es-content-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.fullscreen {
  position: absolute;
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.es-topnav {
  height: $top-panel-height !important;
  box-shadow: 0px 1px 0.7px darken(rgba(177, 184, 188, 1), 40%) !important;
  background: $es-charcoal-blue !important;

  .project-title h2 {
    font-family: $es-font-title;
    font-size: $font-size-lg;
  }
}

.es-logo-toolbar {
  position: relative;
  height: 2.7em;
  padding-top: 0.3em;
}

.es-altitudepicker {
  display: flex;
  width: 40px;
  height: 100px;
}

.es-altitude-slider {
  transform-origin: 75px 75px;
  transform: rotate(-90deg);
  appearance: none;
  width: 200px;
  height: 15px;
  background: $gray-400;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 7px;
}

.es-search {
  width: 300px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.es-search-box {
  position: relative;
  color: $es-white;
  background: $es-medium-light-blue;
  border-radius: 15px;
  height: 30px;
  width: 100%;
  padding: 18px;
  border: 1px solid $dark;
}

.es-search-box:active,
.es-search-box:focus {
  background: $es-light-grey-blue;
  opacity: 100%;
  outline: none;
}

.es-search-results {
  position: absolute;
  color: $dark;
  background: $es-medium-light-blue;
  border-radius: 0px 0px 5px 5px;
  width: 270px;
  margin-left: 1em;
  margin-right: 1em;
  padding-bottom: 6px;
  border: 1px solid $dark;
  border-top: 0px;
  top: 44px;
  box-shadow: 1px 1px 3px #444;
  overflow: hidden;

  .result {
    display: block;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border: none;
    width: 100%;
    text-align: left;
  }

  .result:hover,
  .selected {
    background: $es-light-grey-blue !important;
    color: $white;
  }
}

.es-bottom-bar {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0px;
  height: $bottom-panel-height;
  width: 100%;
  background-color: $es-darkest-blue;
  border-top: 1px solid $es-light-grey-blue;

  .right-box {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: right;
    margin-left: 1em;
    margin-right: 1em;
  }
}

.es-map-color-scale {
  order: 1;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;

  button {
    float: right;
  }
}

.es-color-editor {
  overflow: hidden;

  .es-minmax-group {
    display: flex;
    flex-direction: column;
    margin: 0em 2em 0;
    gap: 0em;
  }

  .es-color-scale {
    img {
      width: 444px;
      border: 1px solid $es-medium-blue;
    }
  }

  .color-scale-wrapper {
    background: #000;
    border-radius: 4px;
    margin-bottom: 1em;
    padding: 10px;
    padding-bottom: 5px;
    padding-top: 38px;
  }

  .color-handle-wrapper {
    margin-right: -10px;
    margin-left: -5px;
    position: relative;
    width: 444px;
  }

  .datapoint {
    position: absolute;
    width: 14px;
    height: 20px;
    left: 0px;
    bottom: 0px;
    translate: x(-50%);
    -webkit-clip-path: polygon(0% 0%, 100% 1%, 100% 67%, 50% 100%, 0 67%);
    clip-path: polygon(0% 0%, 100% 1%, 100% 67%, 50% 100%, 0 67%);
    background-color: $es-medium-light-blue;
    cursor: pointer;

    .inside {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      margin: 2px;
      -webkit-clip-path: polygon(0% 0%, 100% 1%, 100% 67%, 50% 100%, 0 67%);
      clip-path: polygon(0% 0%, 100% 1%, 100% 67%, 50% 100%, 0 67%);
    }

    &.selected {
      cursor: ew-resize;
      background-color: $es-orange-hint;
    }
  }
}

.es-color-scale-select-preset-list {
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;
  height: 370px;
  
  .item {
    border-radius: 8px;
    width: 100%; 
    padding: 0.2em;
    border: 3px solid rgba(1,1,1,0);

    &.selected {
      background: $es-charcoal-blue;
      border: 3px solid $es-charcoal-blue;
    }
  }
}

.es-color-scale-select-field {
  width: 95%;
  padding: 5px;
  background-color: #ffffff;
  color: $es-darkest-blue;
  border: 1px solid $es-darkest-blue;
  margin: 0.5em;
}

.es-color-scale-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 25px;
}

.es-color-scale {
  display: flex;
  position: relative;

  canvas,
  img {
    width: 100%;
    height: 16px;
    border-radius: 5px;
  }
  .min {
    position: absolute;
    top: 17px;
    left: 5px;
  }
  .current {
    text-align: center;
    position: absolute;
    top: 17px;
    width: 100%;
  }
  .max {
    position: absolute;
    top: 17px;
    right: 40px;
  }

  .marker {
    z-index: 100;
    position: absolute;
    top: 0px;
    width: 1px;
    height: 16px;
    background-color: $es-white;
    display: none;
  }

  .unit {
    padding-left: 5px;
  }
}

.gem-color-gradient-editor {
  .gradient-preview {
    position: relative;
    .node-controls {
      position: absolute;
      top: 0px;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      z-index: 100;
    }
  }
}

.gem-information-contents {
  position: relative;
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5em;

  .panel {
    border-radius: 4px;
    padding: 1.5em 3em 3em 2em;
    background-color: $es-white;
    color: $es-black;
  }
}

.es-toolbar {
  position: absolute;
  top: 5px;
  left: 5px;
  margin: 5px;
  width: 35px;
  height: 95%;
  z-index: 1;
  button {
    border: 1px solid $es-charcoal-blue;
    margin: 3px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    outline: none;
    &.bp5-active {
      border-color: #2e2 !important;
      box-shadow: 0 0 8px #fff, 0 0 8px #fff !important;
    }
  }
}

.gem-grid {
  display: grid;
  gap: 1em 1em;
  grid-template-columns: repeat(6, 10em);

  .item {
    .bp5-icon {
      display: block;
      margin-left: 3em;
      margin-right: 3em;
      margin-bottom: 0.7em;
    }
    .label {
      text-align: center;
    }
  }
}

.max-height-60vh {
  max-height: 40vh;
  overflow: scroll;
}

.align-center {
  text-align: center;
}

.es-panel {
  border-top: 1px solid $es-light-grey-blue;

  .es-panel-header {
    display: flex;
    flex-direction: row;
    max-height: 30px;

    h2 {
      font-size: 1em;
      flex-grow: 1;
      margin-top: 7px;
    }

    .es-panel-menu {
      text-align: right;
    }
  }
}

.panel {
  margin: 15px;
}

.bp5-transition-container {
  z-index: 2010;
}

.bp5-tag {
  margin: 2px;
}

.bp5-html-table.bordered {
  border-collapse: collapse;

  tbody {
    border: none;
  }

  th,
  td {
    border: 0.1em solid $es-white;
  }

  th {
    color: $es-white;
    background-color: $es-medium-blue;
  }
}

.bp5-html-table.info {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
  }

  th {
  }
}

.c1 {
  width: 8.3%;
}
.c2 {
  width: 16.6%;
}
.c3 {
  width: 25%;
}
.c4 {
  width: 33.2%;
}
.c6 {
  width: 50%;
}
.c12 {
  width: 100%;
}


@media (min-width: 800px) {
  .layout-desktop {
    display: block;
    height: 100%;
    width: 100vw;
  }
  .layout-mobile {
    display: none;
  }
}


@media (max-width: 799px) {
  .layout-desktop {
    display: none;
  }
  .layout-mobile {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .mobile-info-container {
      max-width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: $font-size-lg;
      text-align: center;

      img {
        margin-bottom: 1em;
        max-height: 5em;
      }

      p {
        margin: 0.5em;
        font-size: 1.25em;
      }
    }

    .mobile-logo {
      max-width: 100%;
      padding-bottom: 1em;
    }
  }
}

.mapview {
  position: relative;
  top: $top-panel-height;
  width: 100vw;
  height: calc(100% - $top-panel-height - $bottom-panel-height);
  overflow-y: hidden;
}

.map-row-wrapper {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.map-viewer-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.map-right-panel {
  flex-grow: 0;
  top: 0em;
  right: 0px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 400px;
  max-height: 100%;

  > div {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
  }
}

.lighter-bg-component {
  background-color: $es-charcoal-blue;
}

body {
  font-family: $es-font-main, "blueprint-icons-16", sans-serif;
  font-size: 10pt;
}

body,
#root {
  background-color: $es-darkest-blue;
  font-size: 10pt;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  letter-spacing: 0;
  line-height: 1.2858142857;
  text-transform: none;
  color: $es-white;
  font-family: $es-font-main, "blueprint-icons-16", sans-serif;

}

.loading-page {
  position: relative;
  height: 100%;

  > .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.viewer-password-page {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > img {
      width: 500px;
      max-width: 40vw;
      margin-bottom: 4em;
    }

    > .description {
      font-size: $font-size-lg;
      margin-bottom: 2em;
    }

    > .error-box {
      min-height: 66px;
      width: 100%;
      margin-top: 2em;

      .callout {
        font-size: $font-size-lg;
        margin-bottom: 0;
      }
    }
  }
}

.project-share-public-viewer {
  display: flex !important;
  align-items: center;
}

.project-share-viewer-example {
  margin-top: 1em;

  > span {
    cursor: pointer;
    color: $es-white-dim;
    &:active {
      color: $es-white;
    }
  }
}

// GlobalNavMenu
a.menu-link {
  color: $es-white;
  text-decoration: none;
  border-bottom: none;
  outline: none;

  > li > span,
  div {
    color: $es-white;
    text-decoration: none;
    border-bottom: none;
    outline: none;
  }
}
a.menu-link:active,
a.menu-link:hover,
a.menu-link:visited {
  color: inherit;
  text-decoration: none;
  border-bottom: none;
  outline: none;
}

.bp5-callout .bp5-icon:first-child {
  top: 50% !important;
  transform: translateY(-50%);
}



.es-dataset-card {
  h2 {
    flex-grow: 1;
    margin-top: 0;
  }

  .information {
    display: flex;
    flex-direction: row;

    .column:not(:first-child) {
      margin-left: 3em;
    }
  }

}
