.gem-selected-entity {
  margin: 9px 0;
  border: 1px solid transparent;

  &.dirty {
    border: 1px solid $es-white-dim;
  }

  .gem-selected-entity-title {
    background: $es-charcoal-blue;
    font-weight: bold;
    padding: 0.5em 0.5em 0 0.5em;
  }

  .gem-selected-entity-label-box {
    background: $es-medium-blue;
    font-weight: bold;
    padding: 0.5em 1em;
  }

  .gem-selected-entity-label {
    min-height: 40px;
    padding: 0 11px;
    display: flex;
    align-items: center;
    font-size: $font-size-lg;
    cursor: pointer;
  }

  .gem-selected-entity-label-input {
    font-size: $font-size-lg;
  }

  .gem-selected-entity-formula-input {
    margin: 0.1em;
  }

  .gem-selected-entity-formula-text-area {
    margin-top: 0.5em;
    width: 100%;
  }

  .gem-selected-entity-body {
    background: $es-medium-blue;
    padding: 0 20px 20px;
    line-height: 1.5em;

    .row {
      border-bottom: 1px solid $es-charcoal-blue;
      padding: 10px 0;

      &.formulaRow {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      .content-column {
        width: 100%;
      }
    }

    .rowLabel {
      font-weight: bold;
    }

    .rowValue {
      display: flex;
      justify-content: flex-end;
    }

    .formulaValue {
      display: flex;
      align-items: center;
      min-height: 41px;
      padding: 0 11px;
      font-size: 16px;
      width: 100%;
      cursor: pointer;
    }

    .formulaFormula {
      width: 100%;
      margin-top: 0.5em;
      padding: 10px;
      // border: 1px solid white;
      overflow-wrap: break-word;
      background-color: $es-charcoal-blue;
      cursor: pointer;
    }
  }

  .gem-selected-entity-formula-actions-bar {
    margin-left: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .gem-selected-entity-formulas-bottom-bar {
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .gem-selected-entity-actions-bar {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }
}
