.setting-toast-container {
  margin-top: $top-panel-height;
}

.settings-toast.styled-toast {
  background-color: $es-darkest-blue;
  color: $es-white;
  font-size: $font-size-base;
  border-radius: 0;
  display: flex;
  align-items: center;
}
