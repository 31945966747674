.es-formula-editor-panel {
  min-height: 190px;
}

.es-formula-editor {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-content: flex-start;
  overflow-x: hidden;


  .es-formula-editor-textarea {
    width: 100%;
    height: 100%;
    min-height: 150px;
    caret-color: #fff;
    caret-shape: block;
    border: 1px solid #333;
    color: transparent;
    background-color: transparent;
    z-index: 100;
    padding: 4px;
    font-family: monospace;
    letter-spacing: normal;
    font-size: 14px;
    line-height: 1.3em;
    resize: none;
    word-wrap: break-word;
    word-break: word;
    word-spacing: normal;



    &::selection {
      color: transparent;
      background: $es-white-dim;
    }

    &:active,
    &:focus {
      border-color: $es-white-dim;
    }
  }

  .es-formula-editor-error {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 4px;
    background-color: $es-darkest-blue;
    border: 1px solid $es-orange-hint;
    color: #fff;
    font-size: 12px;
    text-align: center;
    z-index: 20;
  }

  .es-formula-editor-formatted {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid transparent;
    padding: 4px;
    font-family: monospace;
    letter-spacing: normal;
    font-size: 14px;
    overflow-y: auto;
    cursor: text;
    line-height: 18.2px;
    background-color: darken($es-medium-blue, 10%);
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: word;
    word-spacing: normal;

    .string,
    .quote {
      color: #ffc;
    }

    .float {
      color: #fcc;
    }

    .functioncall {
      color: #cfc;
    }

    .function-intrinsic {
      color: #afa;
    }

    .function-unknown {
      color: #faa;
    }

    .variable {
      color: #ccf;
    }
    .variable:hover {
      text-decoration: underline;
      color: #bbf;
    }

    .operator,
    .comma {
      color: #e3ecf0;
    }

    .lparen, .rparen {
      color: #b06ccd;
    }

    .lbracket, .rbracket {
      color: rgb(0, 189, 41);
    }

    .atsign {
      color: #ea1;
    }

    .error {
      color: red;
    }

    .unparsed {
      color: #e22;
    }
  }

  .button-bar {
    display: grid;
    grid-direction: column;
  }

}


.es-function-lookup-box {
  padding: 0px;
  margin: 0px;
  width: 100%;
  letter-spacing: normal;
  font-size: 14px;
  line-height: 1.3em;
  height: 200px;
  max-height: 200px;
  font-size: $font-size-sm;

  .function-list {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.es-variable-lookup-box {
  padding: 4px;
  letter-spacing: normal;
  font-size: 14px;
  line-height: 1.3em;
  height: 200px;
  max-height: 200px;
  display: flex;
  flex-direction: row;
  flex: 1;  
  font-size: $font-size-sm;

  .variable-list {
    width: 40%;
    height: 100%;
    max-height: 160px;

    .variable-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $es-white;
      font-size: $font-size-lg;
    }

    #tree {
      height: 100%;
      overflow-y: scroll;
    }
  }

  .variable-info {
    width: 60%;
    height: 100%;
    padding: 0.5em;
    overflow-y: scroll;
    overflow-x: hidden;

    h1 { 
      margin-top: 0; 
      font-size: $font-size-lg;
    }
  }
}