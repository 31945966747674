.es-coordinates-box {
  display: flex;
  flex-direction: row;
  padding: 4px;
  height: 1.7em;
  

  input {
    width: 100%;
    border: none;
  }

  .coordinates-text {
    text-align: right;
    font-family: monospace;
    font-size: 90%;
    padding-right: 10px;
    width: 100%;
  }
}

.es-earth-loading-container {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: $es-charcoal-blue;
  padding: 10px;
}

.cesium-widget-credits {
  display: none !important;
}

.es-earth-zoom-buttons-container {
  position: absolute;
  bottom: 0%;
  display: flex;
  flex-direction: column;

  > button:not(:first-of-type) {
    margin-top: 6px;
  }

  > button {
    outline: none;
  }
}

.cesium-performanceDisplay-defaultContainer {
  top: 7px !important;
  right: 48px !important;
}

.cesium-sceneModePicker-dropDown-icon[data-bind*="COLUMBUS_VIEW"] {
  display: none;
}


.es-tool-overlay-measure-distance {
  position: absolute;
  width:    180px;
  height:   100px;
  text-align: center;
  vertical-align: middle;
  font-size: 120%;
  bottom:   0px;
  left:     60px;
  margin:   0px;

  .bp5-dialog-header {
    position: relative;
    width: 100%;
  }
  .body {
    position: relative;
    width: 100%;
    height: 100%;
    padding:  auto;
    padding-top: 1em;
  }
}
