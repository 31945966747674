.es-form-group, .bp5-form-group {
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;

  .es-form-content, .bp5-form-content {
    flex: 1 1 auto;
    min-width: 10rem;
  }

  label, .es-label, .bp5-label {
    font-weight: 600;
    flex: 0 0 auto;
    width: 40%;
    max-width: 15em;
    padding-right: 0.5em;
    text-align: right;

    .es-required, .bp5-text-muted {
      color: $es-red !important;
    }
  }

  .error-info-box {
    margin-left: 1.5rem;
    color: $es-red;
    display: flex;
    white-space: normal;
  }
}


.es-button, .bp5-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-size: 14px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  min-height: 30px;
  min-width: 30px;
}

.es-button > *, .bp5-button > * {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
