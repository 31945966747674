.es-info-wrapper {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.es-info-sidebar {
  flex-grow: 1;
  min-width: 200px;
  padding: 0.3em;
  h2 {
    margin-top: 0px;
    margin-left: 0.5em;
  }

  .bp5-button-group {
    width: 100%;
  }
}

.es-info-content {
  flex-grow: 9;
  background: $es-white;
  padding: 2em;
  color: $es-darkest-blue;
  height: fit-content;
  margin-bottom: 3em;
}

.information-layout-background {
  margin-top: $top-panel-height;
  background: $es-medium-light-blue;
  height: 100%;
  color: $es-black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.information-layout-body {
  min-width: 80em;
  width: 90%;
  background: $es-grey-background;
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - #{$top-panel-height});
  overflow: auto;
}

.centered-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.es-info-sidebar {
  margin-top: 30px;
  border-radius: 0px;
  .bp5-button {
    margin-top: 0.5em;
    border-radius: 2em;
    &:hover {
      background: $es-white-dim;
    }
  }
}

.es-info-content-dark {
  background: $es-darkest-blue;
  margin-top: 40px;
  flex-grow: 9;
  padding-left: 1em;
  padding-top: 0px;
  color: $es-white-dim;
  margin-bottom: 3em;
}
