.auth-background {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;

  .sign-out-button-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 4em;
    height: 4em;
    border-left: 1px solid rgba($es-white, 0.25);
    border-bottom: 1px solid rgba($es-white, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 40%;
    border-top-right-radius: 25%;
    transition: border ease-in 150ms;
    span,
    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    svg {
      fill: $es-white;
      transition: fill ease-in 150ms;
    }
    &:hover,
    &:focus,
    :hover,
    :focus {
      outline: none;
      border-color: $es-yellow;
      svg {
        fill: $es-yellow;
      }
    }
  }
}


.onboard-content-pane {
  position: absolute;
  height: 100%;
  top: 0%;
  right: 10%;
  min-width: 44em;
  width: 80%;
  padding: 0;
  padding-top: 10em;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba($es-darkest-blue, 0.85);
  transition: width ease-in 100ms;
  overflow-y: scroll;

  &.left-pane {
    left: 10%;
    right: auto;
    width: auto;
  }

  h1 {
    border: none;
  }
}

.auth-logo-icon {
  height: 6em;
  margin-bottom: 3em;
}

.auth-logo-text {
  height: 4em;
  width: auto;
  margin-bottom: 1em;
  fill: $es-white;
}

.auth-choice-pane {
  display: flex;
  flex-direction: row;

  .auth-choice {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    padding: 2em;
    font-size: $font-size-base;
    color: $es-white;
    cursor: pointer;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 0.5em;
    ul {
      padding: 0;
      margin-bottom: 3em;
    }
    h1 {
      font-size: $h1-font-size;
    }
    .price {
      font-size: $h2-font-size;
      color: $es-orange-hint;
    }
    .period {
      font-size: $h6-font-size;
      color: $es-white-dim;
    }
    h3 {
      text-align: left;
    }
    .selectbtn {
      margin: 0.5em;
      position: absolute;
      bottom: 0px;
      width: 90%;
      &.active, &.selected {
        background-color: $es-darkest-blue;
      }
    }
    &:hover {
      background-color: $es-darkest-blue;
      opacity: 0.8;
    }
    &.selected {
      background-color: $es-darkest-blue;
      border: 1px solid $es-yellow;
    }
  
  }
}

.settings-main-section > .auth-choice-pane {
  .auth-choice {
    color: $es-darkest-blue;
    width: 30em;

    &:hover {
      background-color: $es-charcoal-blue;
      color: $es-white;
    }
    &.selected {
      background-color: $es-darkest-blue;
      color: $es-white;
    }
  }
}

.es-onboard-steps-trace {
  position: absolute;
  top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  gap: 2em;

  .es-onboard-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    border: 1px solid rgba(0,0,0,0);
    border-radius: 0.5em;

    .step-number {
      width: 1.5em;
      height: 1.5em;
      text-align: center;
      line-height: 1.5em;
      border-radius: 50%;
      background-color: $es-white-dim;
      margin: 0 0.5em;
      margin-bottom: 1em;
      transition: background-color ease-in 100ms;      
      &.valid {
        background-color: $es-orange-hint;
        color: $es-darkest-blue;
      }
    }

    &.current {
      font-weight: 900;
      background-color: $es-darkest-blue;
      border-color: $es-orange-hint;
    
    }
    &.past {
      color: darken($es-white-dim, 30%);
      .step-number.invalid {
        background-color: darken($es-white-dim, 30%);
      }
      .step-number.valid {
        background-color: darken($es-orange-hint, 30%);
      }
    }

    .step-line {
      width: auto;
      height: 1em;
      background-color: $es-white-dim;
      margin: 0 0.5em;
      transition: background-color ease-in 100ms;
      &.active {
        background-color: $es-yellow;
      }
    }
  }
}

.auth-content-pane {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  min-width: 44em;
  width: 44em;
  padding: 4.5em 0;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba($es-darkest-blue, 0.85);
  transition: width ease-in 100ms;
}

.auth-content-body {
  margin-top: 1em;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $font-size-lg;

  &.sign-in {
    margin-top: 3em;
  }

  form {
    width: 100%;
    margin: 1em 0;
  }

  .container {
    width: 100%;
  }

  .text-buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 0.5em;
    a {
      display: inline-block;
      width: fit-content;
      color: $es-white;
      transition: color ease-in 100ms;
      &:hover {
        color: $es-yellow;
      }
    }
  }

  .loading {
    margin: 3em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer-button {
    margin-top: 2em;
  }

  // TODO: Refactor out of auth-content-body?
  .bp5-callout {
    font-size: $font-size-lg;
  }

  .bp5-input-group {
    .bp5-input-action {
      top: 50%;
      transform: translateY(-50%);
      font-size: 0.8em;
      color: $es-darkest-blue;

      &:last-child {
        right: 1em;
      }
    }
  }

  .bp5-input {
    font-size: $font-size-lg;
    height: calc(2.5 * #{$font-size-lg});
    border-radius: 0.25em;
    box-shadow: rgb(208, 208, 208) 4px 4px 8px 0px inset,
      rgba(255, 255, 255, 0.5) -4px -4px 8px 2px inset;
    transition: background-color ease-in 150ms;
    background-color: darken($es-white, 7.5%);
    &:hover {
      background-color: darken($es-white, 2.5%);
    }
    &:focus {
      background-color: $es-white;
    }
  }

  a {
    width: 100%;
    text-decoration: none;
  }

  .bp5-button {
    height: 2.5em;
    font-size: $font-size-lg;
    font-weight: 500;
    color: $es-white;
    width: 100%;
    background-color: $es-light-grey-blue;
    display: flex;
    justify-content: center;
    border-radius: 0.25em;
    transition: background-color ease-in 100ms, filter ease-in 100ms;
    &:hover:not([disabled]) {
      background-color: lighten($es-light-grey-blue, 7.5%);
    }
    &:focus:not([disabled]) {
      outline: none;
      background-color: lighten($es-light-grey-blue, 15%);
    }

    &.primary {
      background-color: darken($es-yellow, 25%);
      &:hover:not([disabled]) {
        background-color: darken($es-yellow, 30%);
      }
      &:focus:not([disabled]) {
        outline: none;
        background-color: darken($es-yellow, 30%);
      }
      &:disabled {
        filter: grayscale(1);
      }
    }
  }
}

.auth-info-box {
  width: 100%;
  margin: 1em 0;
  max-width: 57em;
}

.legal-drawer-link {
  cursor: pointer;
  text-decoration: none;
  color: $es-yellow;
  transition: color ease-in 100ms;
  &:hover {
    color: lighten($es-yellow, 15%);
    text-decoration: none;
  }
}

.legal-drawer {
  background-color: $es-darkest-blue;
  color: $es-white;
  font-size: $font-size-lg;
  padding: 1em;

  .bp5-drawer-header {
    padding-bottom: 1em;
  }

  .legal-body-container {
    padding: 1em 4em 1em 3em;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $es-yellow;
      outline: 1px solid $es-yellow;
    }
  }

  h2 {
    font-size: $h2-font-size;
    margin-bottom: 1em;
  }
  h3 {
    font-size: $h3-font-size;
    margin-bottom: 0.7em;
  }
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $es-white-dim;
  line-height: 0.08em;
  font-size: 0.9em;
  color: $es-white-dim;
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: "Space Grotesk";

  span {
    position: relative;
    background: $es-darkest-blue;
    padding: 0.5px 4px;
  }
}

// Google Button
.bp5-button.google-signin-button {
  font-size: $font-size-lg;
  font-weight: 500;
  color: $es-darkest-blue;
  width: 100%;
  background-color: $es-white;
  display: flex;
  justify-content: center;
  transition: background-color ease-in 100ms;
  &:hover:not([disabled]) {
    background-color: darken($es-white, 7.5%);
  }
  &:focus:not([disabled]) {
    outline: none;
    background-color: darken($es-white, 15%);
  }
  &:disabled {
    img {
      filter: grayscale(1);
    }
  }
}
