.simple-select {
  border: 0;
  outline: none;
  background: $es-grey-background;
  border-radius: 0.5em;
  padding: 0 0.5em;
  width: 17.5em;

  &.large {
    height: 2.25em;
  }

  &.white {
    background: $es-white;
  }

  &.narrow {
    width: auto;
  }

  &.inline {
    width: auto;
    margin: 0.5em;
  }

  &.fill {
    width: 100%;
  }

  &:focus {
    box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0),
      inset 0 0 0 1px rgba(17, 20, 24, 0.1),
      inset 0 1px 1px rgba(17, 20, 24, 0.2);
  }
}

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apikey-message {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 3em 0;
  font: $font-size-lg;
}
