

.es-tabs-tabset, .bp5-tab-list {
    align-self: start;
    flex-grow: 3;
    display: block;
    margin-top: auto;
    height: 2.4em;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0;
    list-style: none;

    .es-tabs-tab, .bp5-tab {
        display: inline-block;
        border: none;
        background: none;
        padding: 0.5em 1em;
        font-size: 1em;
        font-weight: 400;
        color: $es-grey-border;
        cursor: pointer;
        outline: none;
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        margin-right: 0.5em;
        &:hover {
            color: $es-black;
            border-bottom: 2px solid $es-orange-hint;
        }
        &.active, &[aria-selected="true"] {
            color: $es-black;
            font-weight: 600;
            background-color: darken($es-white, 10%);
            border-radius: 0.5em 0.5em 0 0;
            border-bottom: 2px solid $es-orange-hint-bold;
        }
    }


    &.vertical {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 25%;
        overflow: hidden;
        background-color: $es-medium-blue;
    
        .es-tabs-tab {
            background-color: $es-medium-light-blue;
            margin: 0;
            padding: 0.5em 1em;
            width: 100%;
            border-bottom: 1px solid $es-grey-border;
            cursor: pointer;
            color: $es-black;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $es-white;
            padding-top: 1em;
            padding-bottom: 1em;
            text-decoration: none;
            
            .chevron {
                display: none;
            }

            &:hover {
                background-color: $es-medium-light-blue;
            }
        
            &.active {
                background-color: $es-charcoal-blue;
                .chevron {
                    display: block;
                }
            }    
          
        }
    }
}

.es-tabs-tabpanel-container, .bp5-tabs {
    position: relative;
    display: block;
    width: 100%;
    min-height: 200px;
    height: 100%;

    &.vertical {
        width: 70%;
        height: 100%;
        margin: 0em;

        .es-tabs-tabpanel {
            overflow: auto;
        }
    }
}

.es-tabs-tabpanel, .bp5-tab-panel {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    visibility: hidden;
    padding: 0.7em;
    &.active, &[aria-hidden="false"] {
        visibility: visible;
    }
}

.bp5-tab-panel {
}

.bp5-tab-indicator-wrapper {
    display: none;
}

