.og-popup {
  .og-popup-content-wrapper {
    background-color: $es-charcoal-blue;
    .og-popup-content {
      margin: 15px 10px 10px;
      color: $es-white;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }
  .og-popup-tip {
    background-color: $es-charcoal-blue;
  }
}
