.es-timepicker {
  flex-grow: 1;

  #time-area {
    width: 100%;
    height: 100%;
    bottom: 0px;
    cursor: pointer;
  }

}

.date-picker-menu {
  display: flex;
  flex-direction: row;
  padding: 5px;

  .shortcut-range-button-box {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
  }
}


.vis-item {
  font-size: 40%;
}


.vis-time-axis {
}

.vis-timeline {
  border: 0px !important;
  border-radius: 5px;
//  background: $es-darkest-blue;
}

.vis-background {
  height: 100%;
}

.vis-horizontal {
  height: 5px !important;
}

.vis-panel {
  border: none;
}

.vis-center {
  
  border: none !important;
}

.vis-center > .vis-content {
  border-top: 3px solid $es-white-dim !important;
}

.vis-text {
  color: $es-white-dimmer !important;
}

.vis-grid.vis-minor, .vis-grid.vis-major {
  border-color: $es-light-grey-blue !important;
}

.vis-custom-time {
  width: 20px !important;
  height: 20px !important;
  background: $es-yellow !important;
  border-radius: 8px !important;
  transform: translate(-50%, 80px);
}
