

.tile-loading-info-box {
    position: absolute;
    top: 0;
    left: 70px;
    background-color: $es-charcoal-blue;
    padding: 6px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 200px;
}