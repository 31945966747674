// @charset "UTF-8";
.date-range-picker-container {
  display: inline-block;
  margin-left: 0.5em;
  margin-right: 0.5em;

  .date-picker-input-box {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .text-separator {
    margin-left: 0.4em;
    margin-right: 0.4em;
  }
}
