.es-project-list {
    min-height: 100%;
  }
  
  .es-project-list-item {
    border: 1px solid $es-grey-border;
    cursor: pointer;
    padding: 1em;
    padding-top: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 5px;
  
    &:hover {
      background: $es-white-dim;
    }
  
    .bp5-icon {
      margin-right: 0.6em;
    }
    h1 {
      font-weight: 600;
      font-size: $font-size-lg;
      margin-top: 0;
      margin-bottom: 0.2em;
      .bp5-tag {
        font-size: 60%;
        margin-left: 1em;
      }
    }

    .details > div {
      display: inline-block;
      margin-right: 0.8em;
      margin-left: 0.5em;
      font-size: $font-size-sm;
      color: $es-white-dimmer;
      span {
        font-weight: 600;
        padding-right: 0.5em;
      }
    }

    .ownership {
      
    }

    .actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      .title {
        flex-grow: 1;
      }
      button {
        justify-self: flex-end;
        margin-left: 0.5em;
      }
    }

    .description {
      margin: 0.5em;
    }
  }
