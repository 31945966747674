.pill-radio-container {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    padding: 0 2em;
    // border: 1px solid $es-grey-background;
    background: $es-grey-background;
    font-size: $font-size-base;
    cursor: pointer;
    overflow: none;

    &:first-of-type {
      border-top-left-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
    }

    &:last-of-type {
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
    }

    > input[type="radio"] {
      display: none;
    }

    > label {
      font-weight: 400;
      font-size: 0.9em;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
      margin: 0 -2em;
      padding: 0 1em;
      border-radius: 0.5em;
      transition: background-color 100ms ease-in, color 150ms ease-in;

      &:hover {
        background: $es-white-dim;
      }
    }

    > input[type="radio"]:checked + label {
      background: $es-charcoal-blue;
      color: $es-white;
    }
  }
}
